var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fh" },
    [
      _c("div", { staticClass: "wrapper-table" }, [
        _c(
          "div",
          {
            staticClass: "main-row table-row",
            style: _vm.backgroundImageStyle,
          },
          [
            _c("div", { staticClass: "main-cell table-cell" }, [
              !_vm.$store.getters.allowAccess
                ? _c(
                    "div",
                    {
                      staticClass: "box",
                      style: { backgroundColor: _vm.$themePalette.grey.v_100 },
                    },
                    [
                      _vm.themeConfig.signIn.topLogo
                        ? _c("CoreLogo", {
                            staticClass: "logo",
                            style: _vm.themeConfig.signIn.topLogo.style,
                            attrs: {
                              src: _vm.themeConfig.signIn.topLogo.src,
                              alt: _vm.themeConfig.signIn.topLogo.alt,
                            },
                          })
                        : _vm._e(),
                      _vm.themeConfig.signIn.showTitle
                        ? _c(
                            "h1",
                            {
                              staticClass: "kolibri-title",
                              style: [
                                { color: _vm.$themeBrand.primary.v_300 },
                                _vm.themeConfig.signIn.titleStyle,
                              ],
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.logoText) +
                                  "\n          "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c("p", { attrs: { "data-test": "restrictedAccess" } }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$tr("restrictedAccess")) +
                            "\n          "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(_vm._s(_vm.$tr("restrictedAccessDescription"))),
                      ]),
                    ],
                    1
                  )
                : _c(
                    "div",
                    {
                      staticClass: "box",
                      style: { backgroundColor: _vm.$themePalette.grey.v_100 },
                    },
                    [
                      _vm.themeConfig.signIn.topLogo
                        ? _c("CoreLogo", {
                            staticClass: "logo",
                            style: _vm.themeConfig.signIn.topLogo.style,
                            attrs: {
                              src: _vm.themeConfig.signIn.topLogo.src,
                              alt: _vm.themeConfig.signIn.topLogo.alt,
                            },
                          })
                        : _vm._e(),
                      _vm.themeConfig.signIn.showTitle
                        ? _c(
                            "h1",
                            {
                              staticClass: "kolibri-title",
                              style: [
                                { color: _vm.$themeBrand.primary.v_300 },
                                _vm.themeConfig.signIn.titleStyle,
                              ],
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.logoText) +
                                  "\n          "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.themeConfig.signIn.showPoweredBy
                        ? _c(
                            "p",
                            {
                              staticClass: "small-text",
                              style: _vm.themeConfig.signIn.poweredByStyle,
                            },
                            [
                              _vm.oidcProviderFlow
                                ? _c("KButton", {
                                    attrs: {
                                      text: _vm.$tr("poweredByKolibri"),
                                      appearance: "basic-link",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.whatsThisModalVisible = true
                                      },
                                    },
                                  })
                                : _c("KExternalLink", {
                                    attrs: {
                                      text: _vm.$tr("poweredByKolibri"),
                                      primary: true,
                                      href: "https://learningequality.org/r/powered_by_kolibri",
                                      openInNewTab: true,
                                      appearance: "basic-link",
                                    },
                                  }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._t("default"),
                      !_vm.hideCreateAccount && _vm.canSignUp
                        ? _c(
                            "p",
                            { staticClass: "create" },
                            [
                              _c("KRouterLink", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  text: _vm.userString("createAccountAction"),
                                  to: _vm.signUpPage,
                                  primary: false,
                                  appearance: "raised-button",
                                  disabled: _vm.busy,
                                  "data-test": "createUser",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        _vm._l(_vm.loginOptions, function (component) {
                          return _c(component, {
                            key: component.name,
                            tag: "component",
                          })
                        }),
                        1
                      ),
                      _vm.showGuestAccess
                        ? _c(
                            "p",
                            { staticClass: "guest small-text" },
                            [
                              _c("KExternalLink", {
                                attrs: {
                                  text: _vm.$tr("accessAsGuest"),
                                  href: _vm.guestURL,
                                  primary: true,
                                  appearance: "basic-link",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
            ]),
          ]
        ),
        _c("div", { staticClass: "table-row" }, [
          _c(
            "div",
            {
              staticClass: "footer-cell table-cell",
              style: { backgroundColor: _vm.$themeTokens.surface },
            },
            [
              _c("LanguageSwitcherFooter"),
              _c(
                "div",
                { staticClass: "small-text" },
                [
                  _c("span", { staticClass: "version-string" }, [
                    _vm._v(
                      "\n            " + _vm._s(_vm.versionMsg) + "\n          "
                    ),
                  ]),
                  _vm.themeConfig.signIn.showKolibriFooterLogo
                    ? _c("CoreLogo", { staticClass: "footer-logo" })
                    : _c("span", [_vm._v(" • ")]),
                  _c("KButton", {
                    attrs: {
                      text: _vm.coreString("usageAndPrivacyLabel"),
                      appearance: "basic-link",
                    },
                    on: {
                      click: function ($event) {
                        _vm.privacyModalVisible = true
                      },
                    },
                  }),
                  _vm.themeConfig.signIn.backgroundImgCredit
                    ? [
                        _c("span", [_vm._v(" • ")]),
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$tr("photoCreditLabel", {
                                photoCredit:
                                  _vm.themeConfig.signIn.backgroundImgCredit,
                              })
                            ) +
                            "\n          "
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm.privacyModalVisible
        ? _c("PrivacyInfoModal", {
            on: {
              submit: function ($event) {
                _vm.privacyModalVisible = false
              },
              cancel: function ($event) {
                _vm.privacyModalVisible = false
              },
            },
          })
        : _vm._e(),
      _vm.whatsThisModalVisible
        ? _c(
            "KModal",
            {
              attrs: {
                title: _vm.$tr("whatsThis"),
                submitText: _vm.coreString("closeAction"),
              },
              on: {
                submit: function ($event) {
                  _vm.whatsThisModalVisible = false
                },
                cancel: function ($event) {
                  _vm.whatsThisModalVisible = false
                },
              },
            },
            [
              _c("p", [_vm._v(_vm._s(_vm.$tr("oidcGenericExplanation")))]),
              _c(
                "p",
                [
                  _c("KExternalLink", {
                    attrs: {
                      text: "https://learningequality.org/kolibri",
                      primary: true,
                      href: "https://learningequality.org/r/powered_by_kolibri",
                      openInNewTab: true,
                      appearance: "basic-link",
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }